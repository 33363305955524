// extracted by mini-css-extract-plugin
export var container = "styles-module--container--zOfvO";
export var description = "styles-module--description--v2Kuu";
export var desktop = "1340px";
export var fullBorders = "styles-module--fullBorders--JjsuR";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--O8vnw";
export var leftColumnGrid = "styles-module--leftColumnGrid--jq-BL";
export var leftColumnText = "styles-module--leftColumnText--35KGw";
export var mobile = "400px";
export var removeTopBorder = "styles-module--removeTopBorder--QblrJ";
export var rightColumn = "styles-module--rightColumn--NlY2W";
export var rightColumnContainer = "styles-module--rightColumnContainer--bzVbt";
export var slice = "styles-module--slice--Wxz1A";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";