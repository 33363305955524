// extracted by mini-css-extract-plugin
export var button = "styles-module--button--iuFTf";
export var container = "styles-module--container--P34MG";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logomark = "styles-module--logomark--D4D0m";
export var mobile = "400px";
export var right = "styles-module--right--j2iHP";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";