import React from "react";
import cn from "classnames";
import {
  NoJs,
  Theme,
  Header,
  PageNavButtons,
  Footer,
  Notification,
  ToolkitFooter
} from "~components";
import WebsiteLayout from "./WebsiteLayout";
import * as styles from "./styles.module.scss";
import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/global.css";

interface IProps {
  children: React.ReactNode;
  className?: string;
  pageContext?: {
    layout: string;
  };
  location: {
    pathname: string;
  };
}

const Layout = ({ children, className, pageContext, location }: IProps) => {
  if (
    pageContext?.layout === "website" ||
    location?.pathname?.match?.("/build/")
  ) {
    return <WebsiteLayout>{children}</WebsiteLayout>;
  }

  return (
    <>
      <NoJs />
      <Theme />

      <div className={cn(styles.container, className)}>{children}</div>
      <Header />
      <PageNavButtons />
      <Footer />
      <Notification />

      <ToolkitFooter />
    </>
  );
};

export default Layout;
