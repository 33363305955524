// extracted by mini-css-extract-plugin
export var container = "styles-module--container--Vvpfx";
export var content = "styles-module--content--xhCHL";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomLarge = "styles-module--paddingBottomLarge--jYluh";
export var paddingBottomRegular = "styles-module--paddingBottomRegular--5Rijj";
export var paddingBottomSmall = "styles-module--paddingBottomSmall--1-z-g";
export var paddingBottomXSmall = "styles-module--paddingBottomXSmall--04C00";
export var paddingTopLarge = "styles-module--paddingTopLarge--bFHjM";
export var paddingTopRegular = "styles-module--paddingTopRegular--8nDtH";
export var paddingTopSmall = "styles-module--paddingTopSmall--0wzy3";
export var paddingTopXSmall = "styles-module--paddingTopXSmall--uPGhV";
export var paddingXRegular = "styles-module--paddingXRegular--v1ccV";
export var paddingXSmall = "styles-module--paddingXSmall--0AeT7";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";