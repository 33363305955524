import React from "react";
import {
  SliceConfig,
  Grid,
  PortableTextRenderer,
  IntersectionAnimation
} from "~components";
import { ISliceConfig } from "~schemas";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  noAnimation?: boolean;
  isStacked?: boolean;
  data: {
    _key?: string;
    _rawLeftColumn?: any;
    _rawRightColumn?: any;
    _rawDescription?: any;
    removeTopBorder?: boolean;
    sliceConfig?: ISliceConfig;
  };
}

const TextArticle = ({
  data: {
    _rawLeftColumn,
    _rawRightColumn,
    _rawDescription,
    removeTopBorder,
    sliceConfig
  },
  noAnimation,
  isStacked
}: IProps) => (
  <section
    className={cn(styles.container, {
      [styles.removeTopBorder]: removeTopBorder,
      [styles.fullBorders]: isStacked
    })}
  >
    <SliceConfig
      className={cn([{ [styles.slice]: isStacked }])}
      config={
        isStacked
          ? {
              slicePadding: {
                paddingTop: "none",
                paddingBottom: "none",
                paddingX: "none"
              }
            }
          : sliceConfig
      }
    >
      <Grid>
        {_rawLeftColumn && (
          <div className={styles.leftColumn}>
            <Grid className={styles.leftColumnGrid}>
              <IntersectionAnimation
                className={styles.leftColumnText}
                delay={150}
                trigger={noAnimation}
              >
                <PortableTextRenderer rawText={_rawLeftColumn} />
              </IntersectionAnimation>

              {_rawDescription && (
                <IntersectionAnimation
                  className={styles.description}
                  delay={150}
                  trigger={noAnimation}
                >
                  <PortableTextRenderer rawText={_rawDescription} />
                </IntersectionAnimation>
              )}
            </Grid>
          </div>
        )}

        {_rawRightColumn && (
          <div className={styles.rightColumn}>
            <IntersectionAnimation
              className={styles.rightColumnContainer}
              delay={150}
              trigger={noAnimation}
            >
              <PortableTextRenderer rawText={_rawRightColumn} />
            </IntersectionAnimation>
          </div>
        )}
      </Grid>
    </SliceConfig>
  </section>
);

export default TextArticle;
