import React from "react";
import cn from "classnames";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  config?: ISliceConfig;
  className?: string;
}

const SliceConfig = ({ children, config, className }: IProps) => {
  const { backgroundColor, textColor, slicePadding } = config || {};
  const {
    paddingTop = `regular`,
    paddingBottom = `regular`,
    paddingX = `regular`
  } = slicePadding || {};

  return (
    <div
      style={{
        color: textColor?.hex || `var(--color-white)`,
        backgroundColor: backgroundColor?.hex || `var(--color-navy)`
      }}
      className={cn(
        styles.container,
        {
          [styles.paddingTopLarge]: paddingTop === `large`,
          [styles.paddingTopRegular]: paddingTop === `regular`,
          [styles.paddingTopSmall]: paddingTop === `small`,
          [styles.paddingTopXSmall]: paddingTop === `extra-small`,
          [styles.paddingBottomLarge]: paddingBottom === `large`,
          [styles.paddingBottomRegular]: paddingBottom === `regular`,
          [styles.paddingBottomSmall]: paddingBottom === `small`,
          [styles.paddingBottomXSmall]: paddingBottom === `extra-small`,
          [styles.paddingXRegular]: paddingX === `regular`,
          [styles.paddingXSmall]: paddingX === `small`
        },
        className
      )}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SliceConfig;
