import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { SVG } from "~components";
import { Link } from "gatsby";
import { useBreakpoints } from "~hooks";
import * as styles from "./styles.module.scss";

interface IProps {
  className?: string;
  to: string;
  text: string;
  direction: "prev" | "next";
}

const PageNavButton = ({ className, to, text, direction }: IProps) => {
  const { breakpoints } = useBreakpoints();

  const buttonRef = useRef(
    null
  ) as React.MutableRefObject<HTMLAnchorElement | null>;

  const isDesktop = breakpoints.largeTablet;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === `ArrowLeft` && direction === `prev`) {
      buttonRef.current?.click();
    } else if (e.key === `ArrowRight` && direction === `next`) {
      buttonRef.current?.click();
    }
  };

  const getButtonText = (title: string) => {
    const buttonText = isDesktop ? `[${title}]` : title;

    return buttonText;
  };

  useEffect(() => {
    window.addEventListener(`keydown`, handleKeyDown);

    return () => {
      window.removeEventListener(`keydown`, handleKeyDown);
    };
  }, []);

  return (
    // Don't know how to resolve ref type mismatch
    // https://github.com/gatsbyjs/gatsby/issues/34325
    // @ts-ignore-next-line
    <Link ref={buttonRef} className={cn(className, styles.button)} to={to}>
      <div className={styles.content}>
        <span className={cn(["caption", styles.text])}>
          {getButtonText(text)}
        </span>

        {direction === `prev` && (
          <SVG svg="arrowLeft" className={styles.icon} />
        )}
        {direction === `next` && (
          <SVG svg="arrowRight" className={styles.icon} />
        )}
      </div>
    </Link>
  );
};

export default PageNavButton;
