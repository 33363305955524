// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--UzedH";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mediaItemContainer = "styles-module--mediaItemContainer--7LXdn";
export var mobile = "400px";
export var oneColumnLayout = "styles-module--oneColumnLayout--nmTLv";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var threeColumnLayout = "styles-module--threeColumnLayout--ZYAW9";
export var twoColumnLayout = "styles-module--twoColumnLayout--6Z3Ty";
export var video = "styles-module--video--7krZP";