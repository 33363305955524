// extracted by mini-css-extract-plugin
export var container = "styles-module--container--H09ZH";
export var description = "styles-module--description--AnWFX";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--jrIEp";
export var leftColumnGrid = "styles-module--leftColumnGrid--EvFEM";
export var mobile = "400px";
export var removeTopBorder = "styles-module--removeTopBorder--gBQGf";
export var rightColumn = "styles-module--rightColumn--UQpop";
export var rightColumnContainer = "styles-module--rightColumnContainer--qLJmO";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";