import React, { useEffect, useState } from "react";
import {
  SliceConfig,
  Grid,
  PageLink,
  IntersectionAnimation
} from "~components";
import { usePageNavigation, useApp } from "~hooks";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { ISliceConfig } from "~schemas";

interface IProps {
  data: {
    sliceConfig: ISliceConfig;
  };
}

const Menulist = ({ data: { sliceConfig } }: IProps) => {
  const { pageCategories } = usePageNavigation();
  const { pathname } = useApp();

  const [isVisible, setIsVisible] = useState(false);

  const { ref, inView } = useInView({
    rootMargin: `-200px`
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <nav className={styles.container} ref={ref}>
      <SliceConfig className={styles.sliceConfig} config={sliceConfig}>
        {pageCategories?.map((category, i) => (
          <div
            key={`menu-list-item-${category.id}`}
            className={styles.containerInner}
          >
            <div
              className={cn([
                styles.categoryGroup,
                { [styles.visible]: isVisible }
              ])}
            >
              <Grid>
                <h4 className={cn(`caption`, styles.categoryTitle)}>
                  {category.title}
                </h4>

                <ul className={styles.links}>
                  {category.pages.map((page) => {
                    const isActivePage = pathname?.includes(page.slug.current);

                    return (
                      <li
                        key={page.id}
                        className={cn([
                          styles.listItem,
                          { [styles.visible]: isVisible }
                        ])}
                      >
                        <PageLink
                          className={cn([
                            styles.link,
                            { [styles.visible]: isVisible },
                            { [styles.disabled]: page.disabled }
                          ])}
                          disabled={page.disabled}
                          title={page.title}
                          isActivePage={isActivePage}
                          slug={page.slug}
                        />
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </div>
          </div>
        ))}
      </SliceConfig>
    </nav>
  );
};

export default Menulist;
