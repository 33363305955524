// extracted by mini-css-extract-plugin
export var container = "styles-module--container--qsf3P";
export var content = "styles-module--content--MPKMx";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--wZdrV";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var left = "styles-module--left--GCuy-";
export var leftGrid = "styles-module--leftGrid--xdxfB";
export var marqueeContainer = "styles-module--marqueeContainer--EvKiA";
export var marqueeHorizontal = "styles-module--marquee-horizontal--chbLY";
export var marqueeHorizontalAppear = "styles-module--marquee-horizontal-appear--2qf-4";
export var marqueeVertical = "styles-module--marquee-vertical--V6rVS";
export var marqueeVerticalAppear = "styles-module--marquee-vertical-appear--8C4zI";
export var mesh = "styles-module--mesh--1ffx-";
export var mobile = "400px";
export var right = "styles-module--right--FQtmN";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var table = "styles-module--table--l86ob";
export var tableItem = "styles-module--tableItem--rMmkD";
export var tablet = "769px";
export var title = "styles-module--title--7dOHz";
export var verticalText = "styles-module--verticalText--B9oSx";
export var video = "styles-module--video--HkozA";
export var videoWrapper = "styles-module--videoWrapper--iscC7";