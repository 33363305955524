// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--u-w84";
export var column = "styles-module--column--UznbE";
export var columnHeader = "styles-module--columnHeader--ABQw7";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--1hx-n";
export var imageContainer = "styles-module--imageContainer--E35cw";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--vXn0g";