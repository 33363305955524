import React, { useState } from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  toggleButtonStyle?: {
    style: string;
  };
  backgroundColor?: string;
}

const TypeGrid = ({ toggleButtonStyle, backgroundColor }: IProps) => {
  const [letterCase, setLetterCase] = useState(`uppercase`);

  const characters = `abcdefghijklmnopqrstuvwxyz0123456789♡+$←→™`.split(``);

  return (
    <div
      className={cn(styles.grid, {
        [styles.uppercase]: letterCase === `uppercase`
      })}
    >
      {characters.map((character, i) => (
        <button
          type="button"
          style={{ backgroundColor }}
          onClick={() =>
            setLetterCase((prev) =>
              prev === `uppercase` ? `lowercase` : `uppercase`
            )
          }
          key={i}
          className={styles.gridItem}
        >
          <div className={styles.gridItemContent}>{character}</div>
        </button>
      ))}
    </div>
  );
};

export default TypeGrid;
