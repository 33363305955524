import React from "react";
import cn from "classnames";
import COLORS from "~data/colors.json";
import { SliceConfig, IntersectionAnimation } from "~components";
import { useInView } from "react-intersection-observer";
import { hexToRGB } from "~utils";
import { ISliceConfig } from "~schemas";
import { useApp } from "~hooks";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    category: string;
    sliceConfig: ISliceConfig;
  };
}

const ColorGrid = ({ data: { category, sliceConfig } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  const { updateNotificationMessage } = useApp();

  const copyHexToClipboard = (hex: string) => {
    navigator.clipboard.writeText(hex);
    updateNotificationMessage(`Hex code copied!`);
  };

  return (
    <section>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <ul
            className={cn([
              styles.grid,
              { [styles.primary]: category === "primary" }
            ])}
            ref={ref}
          >
            {Object.keys(COLORS).map((colorKey, i) => {
              const color = COLORS[colorKey];
              const { swatch, category: colorCategory, hex, display } = color;

              if (colorCategory !== category) return null;

              const rgb = hexToRGB(hex);

              const fontColor = display === `dark` ? `white` : `black`;
              const border =
                swatch.toLowerCase() === "black" ||
                swatch.toLowerCase() === "masterbrand navy";
              // const backgroundColor = colorKey === "black" ? "#1E1E1E" : hex;

              return (
                <div className={styles.gridItemContainer}>
                  <IntersectionAnimation
                    trigger={inView}
                    key={i}
                    animation="fadeGrow"
                    delay={i * 50}
                  >
                    <li
                      className={cn([
                        styles.gridItem,
                        { [styles.border]: border }
                      ])}
                      style={{ background: hex, color: fontColor }}
                    >
                      <button
                        type="button"
                        className={styles.gridItemContent}
                        onClick={() => copyHexToClipboard(hex)}
                      >
                        <p className="b2">{swatch}</p>

                        <div>
                          <p className="b2">{hex}</p>
                          <p className="b2">{`R ${rgb?.r || `0`}  G ${
                            rgb?.g || `0`
                          }  B ${rgb?.g || `0`}`}</p>
                        </div>
                      </button>
                    </li>
                  </IntersectionAnimation>
                </div>
              );
            })}
          </ul>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default ColorGrid;
