// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--L6nIU";
export var appearDown = "styles-module--appear-down--wJQ7g";
export var appearLeft = "styles-module--appear-left--XADZq";
export var appearRight = "styles-module--appear-right--CYTXe";
export var appearUp = "styles-module--appear-up--1fKWL";
export var bgOverlay = "styles-module--bgOverlay--PPbVd";
export var categoryGroup = "styles-module--categoryGroup--byBka";
export var desktop = "1340px";
export var giant = "2200px";
export var hamburger = "styles-module--hamburger--uoNZD";
export var header = "styles-module--header--b+ECC";
export var homeLink = "styles-module--homeLink--9SYFi";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var line = "styles-module--line--2IPHM";
export var logoImage = "styles-module--logoImage--+V-jZ";
export var logomark = "styles-module--logomark--ns266";
export var mobile = "400px";
export var nav = "styles-module--nav--Vm97V";
export var navContainer = "styles-module--navContainer--CkHSk";
export var navItems = "styles-module--navItems--nUZzV";
export var open = "styles-module--open--fe-XW";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var visible = "styles-module--visible--et0xl";