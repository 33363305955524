import React from "react";
import {
  Button,
  SliceConfig,
  IntersectionAnimation,
  Grid,
  PortableTextRenderer
} from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    sliceConfig: ISliceConfig;
    _rawBody: object;
    buttonText: string;
    file: string;
  };
}

const Hero = ({
  data: { title, buttonText, file, _rawBody, sliceConfig }
}: IProps) => {
  return (
    <section className={styles.container}>
      <div className={styles.textContainer}>
        <Grid className={styles.grid}>
          <IntersectionAnimation className={styles.title}>
            <h1 className="d1">{title}</h1>
          </IntersectionAnimation>
        </Grid>

        <IntersectionAnimation className={styles.body}>
          <PortableTextRenderer rawText={_rawBody} />

          {file && buttonText && (
            <Button href={file} iconRight="arrowDownload" fitContent outline>
              <span>{buttonText}</span>
            </Button>
          )}
        </IntersectionAnimation>
      </div>
    </section>
  );
};

export default Hero;
