import React from "react";
import { SliceConfig } from "~components";
import { ISliceConfig } from "~schemas";
import { useApp } from "~hooks";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    _key: string;
    items: Array<string>;
    sliceConfig?: ISliceConfig;
  };
}

const TextGrid = ({ data: { _key, items, sliceConfig } }: IProps) => {
  if (!items?.[0]) return;

  const { updateNotificationMessage } = useApp();

  const copyItemToClipboard = (item: string) => {
    navigator.clipboard.writeText(item);
    updateNotificationMessage(`Copied to clipboard!`);
  };

  return (
    <section className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <div className={styles.grid}>
          {items.map((item) => {
            const key = `text-grid-item-${item}-${_key}`;

            return (
              <button
                key={key}
                type="button"
                className={styles.gridItem}
                onClick={() => copyItemToClipboard(item)}
              >
                <p className="d2">{item}</p>
              </button>
            );
          })}
        </div>
      </SliceConfig>
    </section>
  );
};

export default TextGrid;
