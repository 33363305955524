import React from "react";
import {
  Button,
  SliceConfig,
  IntersectionAnimation,
  Grid,
  PortableTextRenderer
} from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    sliceConfig: ISliceConfig;
    _rawBody: object;
    file: {
      asset: {
        url: string;
      };
    };
  };
}

const WebsiteHero = ({
  data: { title, file, _rawBody, sliceConfig }
}: IProps) => {
  return (
    <section className={styles.container}>
      <div className={styles.textContainer}>
        <Grid className={styles.grid}>
          <IntersectionAnimation className={styles.title}>
            <h1 className="d1">{title}</h1>
          </IntersectionAnimation>
        </Grid>

        <IntersectionAnimation className={styles.body}>
          <PortableTextRenderer rawText={_rawBody} />

          {file?.asset?.url && (
            <Button
              href={file?.asset?.url}
              iconRight="arrowDownload"
              fitContent
              outline
            >
              <span>Button</span>
            </Button>
          )}
        </IntersectionAnimation>
      </div>
    </section>
  );
};

export default WebsiteHero;
