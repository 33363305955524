import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Accordian which can reveal information when clicked and opened.
 *
 * @param  {string}  	title		 		Header text
 * @param  {string}  	content 		Text revealed when opened
 * @return {node}
 */

const HoverExpander = ({ title, content, isHovered = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(60);
  const contentRef = useRef();

  const calculateContentHeight = () => {
    const height = contentRef.current.offsetHeight;
    setContentHeight(height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  useEffect(() => {
    setIsOpen(isHovered);
  }, [isHovered]);

  return (
    <>
      <div className={styles.titleContainer} aria-expanded={isOpen}>
        <span className={cn(`h2`, styles.title)}>{title}</span>
        <SVG svg="arrowRight" className={styles.arrow} />
      </div>
      <div
        className={styles.contentContainer}
        aria-hidden={!isOpen}
        style={{ height: `${isOpen ? contentHeight : 0}px` }}
      >
        <p
          ref={contentRef}
          className={cn(`b1`, styles.contentText, { [styles.visible]: isOpen })}
        >
          {content}
        </p>
      </div>
    </>
  );
};

export default HoverExpander;
