import React, { useState } from "react";
import cn from "classnames";
import { HoverExpander } from "~components";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    body: string;
    background: string;
    image: string;
  };
  children: React.ReactNode;
  className?: string;
}

const WebsiteHomepageItem = ({
  data: { title, body, background, image },
  children,
  className
}: IProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className={styles.column}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.columnHeader}>
        <HoverExpander title={title} content={body} isHovered={isHovered} />
      </div>
      <figure
        style={{ backgroundColor: background }}
        className={styles.imageContainer}
      >
        <img className={styles.image} src={image} alt={body} />
      </figure>
    </div>
  );
};

export default WebsiteHomepageItem;
