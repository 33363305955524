// extracted by mini-css-extract-plugin
export var colOne = "styles-module--colOne--I5vZB";
export var colTwo = "styles-module--colTwo--mim80";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var properties = "styles-module--properties--EOXLs";
export var propertyValues = "styles-module--propertyValues--jYc9z";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";