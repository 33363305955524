import React from "react";
import {
  WebsiteGrid,
  WebsiteHomepageItem,
  WebsiteHomepageFooter
} from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    sliceConfig: ISliceConfig;
    _rawBody: object;
    file: {
      asset: {
        url: string;
      };
    };
  };
}

const WebsiteHomepage = () => {
  const columnItems = [
    {
      image: "/images/cmc-home-atf-1.jpg",
      title: "Liquidity",
      body: "TKTKTK"
    },
    {
      image: "/images/cmc-home-atf-2.png",
      title: "Partnerisation",
      body: "TKTKTK",
      background: "var(--color-cool-grey-40)"
    },
    {
      image: "/images/cmc-home-atf-3.png",
      title: "Connectivity",
      body: "TKTKTK",
      background: "var(--color-cool-grey-20)"
    }
  ];

  return (
    <section className={styles.container}>
      <WebsiteGrid className={styles.grid}>
        {columnItems.map((columnItem) => {
          let i;

          let isHovered = false;

          return <WebsiteHomepageItem data={columnItem} />;
        })}
      </WebsiteGrid>

      <WebsiteHomepageFooter />
    </section>
  );
};

export default WebsiteHomepage;
