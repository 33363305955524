// extracted by mini-css-extract-plugin
export var border = "styles-module--border--Fw1dQ";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--npyFD";
export var gridItem = "styles-module--gridItem--M+2Lc";
export var gridItemContainer = "styles-module--gridItemContainer--VVzkt";
export var gridItemContent = "styles-module--gridItemContent--ZZ8os";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var primary = "styles-module--primary--fBLW3";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";