// extracted by mini-css-extract-plugin
export var button = "styles-module--button--VbvcY";
export var content = "styles-module--content--8145u";
export var disabled = "styles-module--disabled--d+kvJ";
export var fitContent = "styles-module--fitContent--STKUg";
export var fluid = "styles-module--fluid---0mQf";
export var icon = "styles-module--icon--2Ajda";
export var left = "styles-module--left--gD4zJ";
export var light = "styles-module--light--ZZtLk";
export var loader = "styles-module--loader--SBEjp";
export var loaderContainer = "styles-module--loaderContainer--7FPAn";
export var loading = "styles-module--loading--DUfZ4";
export var outline = "styles-module--outline--K+cgf";
export var primary = "styles-module--primary--07I4A";
export var right = "styles-module--right--YbAUc";
export var secondary = "styles-module--secondary--1DiKu";
export var spin = "styles-module--spin--025cE";
export var text = "styles-module--text--IUuQw";