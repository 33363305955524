import React from "react";
import cn from "classnames";
import { SliceConfig, IntersectionAnimation } from "~components";
import { ISliceConfig } from "~schemas";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    images: [
      {
        heading: string;

        image: {
          asset: {
            gatsbyImageData: any;
          };
        };
      }
    ];

    sliceConfig: ISliceConfig;
  };
}

const ImagesTwoCol = ({ data: { images, sliceConfig } }: IProps) => {
  if (!images?.[0]) {
    return null;
  }

  return (
    <section>
      <SliceConfig config={sliceConfig}>
        <div className={styles.images}>
          {images.map(({ heading, image }, i) => {
            return (
              <IntersectionAnimation
                className={styles.imageContainer}
                animation="fadeGrow"
              >
                {heading && (
                  <p className={cn(["h3", styles.imageHeading])}>{heading}</p>
                )}
                <GatsbyImage
                  style={{ width: `100%` }}
                  alt=""
                  image={image.asset.gatsbyImageData}
                />
              </IntersectionAnimation>
            );
          })}
        </div>
      </SliceConfig>
    </section>
  );
};

export default ImagesTwoCol;
