import React from "react";
import cn from "classnames";
import { Grid, SliceConfig, IntersectionAnimation } from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    font: string;
    content: string;
    sliceConfig: ISliceConfig;
  };
}

const TypeExample = ({ data: { font, content, sliceConfig } }: IProps) => {
  const getFontProperties = (font: string) => {
    let fontProperties;

    switch (font) {
      case "neue-haas-grotesk-display-pro":
        fontProperties = {
          label: "Neue Haas Grotesk",
          family: "Roman",
          lineHeight: "90%",
          letterSpacing: "-1%",
          fontStyles: {
            fontFamily: "Neue Haas Grotesk Display Pro",
            fontSize: "164px",
            lineHeight: "90%",
            letterSpacing: "-1.64x"
          }
        };
        break;

      case "neue-haas-grotesk-text-pro":
        fontProperties = {
          label: "Neue Haas Grotesk",
          family: "Regular",
          lineHeight: "90%",
          letterSpacing: "-1%",
          fontStyles: {
            fontFamily: "Neue Haas Grotesk Text Pro",
            fontSize: "164px",
            lineHeight: "90%",
            letterSpacing: "-1.64x"
          }
        };
        break;

      default:
        break;
    }

    return fontProperties;
  };

  const fontProperties = getFontProperties(font);

  if (!fontProperties) return null;

  return (
    <section className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <Grid className={styles.grid}>
            <div className={styles.properties}>
              <p className="caption">{fontProperties.label}</p>

              <div className={cn(["caption", styles.propertyValues])}>
                <p>{fontProperties.family}</p>
                <p>{fontProperties.fontStyles.fontSize}</p>
                <p>{fontProperties.lineHeight}</p>
                <p>{fontProperties.letterSpacing}</p>
              </div>
            </div>

            <p style={fontProperties.fontStyles} className={styles.content}>
              {content}
            </p>
          </Grid>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default TypeExample;
