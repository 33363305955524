import React from "react";
import cn from "classnames";
import { Grid, SliceConfig, IntersectionAnimation } from "~components";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    font: string;
    sliceConfig: ISliceConfig;
  };
}

const TypeExampleTwoCol = ({ data: { font, sliceConfig } }: IProps) => {
  const getFontProperties = (font: string) => {
    let fontProperties;

    switch (font) {
      case "neue-haas-grotesk-display-pro":
        fontProperties = {
          label: "Neue Haas Grotesk",
          family: "Roman",
          lineHeight: "90%",
          letterSpacing: "-1%",
          fontStyles: {
            fontFamily: "Neue Haas Grotesk Display Pro",
            fontSize: "14px",
            lineHeight: "120%",
            letterSpacing: "0.14px"
          }
        };
        break;

      case "neue-haas-grotesk-text-pro":
        fontProperties = {
          label: "Neue Haas Grotesk",
          family: "Regular",
          lineHeight: "120%",
          letterSpacing: "1%",
          fontStyles: {
            fontFamily: "Neue Haas Grotesk Text Pro",
            fontSize: "14px",
            lineHeight: "120%",
            letterSpacing: "0.14px"
          }
        };
        break;

      default:
        break;
    }

    return fontProperties;
  };

  console.log(font, "font");

  const fontProperties = getFontProperties(font);

  if (!fontProperties) return null;

  return (
    <section className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <Grid className={styles.grid}>
            <div className={styles.properties}>
              <p className="caption">{fontProperties.label}</p>

              <div className={cn(["caption", styles.propertyValues])}>
                <p>{fontProperties.family}</p>
                <p>{fontProperties.fontStyles.fontSize}</p>
                <p>{fontProperties.lineHeight}</p>
                <p>{fontProperties.letterSpacing}</p>
              </div>
            </div>

            <p style={fontProperties.fontStyles} className={styles.colOne}>
              Multi-asset liquidity provision and trading technology refers to
              the infrastructure and mechanisms that enable the efficient
              trading and provision of liquidity across multiple asset classes
              in financial markets. Liquidity provision involves making assets
              readily available for trading, while trading technology
              encompasses the tools and systems used to execute trades. In the
              context of multi-asset trading, liquidity provision involves
              ensuring that there is a sufficient supply of buyers and sellers
              for various types of financial instruments, such as stocks, bonds,
              derivatives, currencies, commodities, and more. This liquidity
              allows market participants to easily buy or sell assets without
              significantly impacting their prices. Liquidity providers play a
              crucial role in facilitating smooth market functioning by
              constantly offering bids to buy and offers to sell assets, thereby
              reducing bid-ask spreads and enhancing market depth.
            </p>

            <p style={fontProperties.fontStyles} className={styles.colTwo}>
              Trading technology plays a vital role in enabling efficient
              execution of trades across different asset classes. It encompasses
              various electronic platforms, algorithms, trading software, and
              connectivity systems that facilitate the matching of buy and sell
              orders. These technologies enable market participants, such as
              individual traders, institutional investors, and market makers, to
              access liquidity pools, execute trades swiftly, and manage their
              portfolios effectively. Overall, multi-asset liquidity provision
              and trading technology form the backbone of modern financial
              markets, facilitating seamless trading across diverse asset
              classes and enhancing market efficiency. These technologies enable
              market participants to access liquidity, execute trades quickly,
              and manage risks effectively, thereby promoting transparency and
              liquidity in global financial markets.
            </p>
          </Grid>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default TypeExampleTwoCol;
