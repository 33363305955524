import React from "react";
import { SliceConfig } from "~components";
import { TextArticle } from "~slices";
import { ISliceConfig } from "~schemas";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  removeTopBorder?: boolean;
  data: {
    textArticles?: Array<{
      _key: string;
    }>;
    sliceConfig?: ISliceConfig;
  };
}

const StackedTextArticle = ({
  data: { textArticles, sliceConfig },
  removeTopBorder
}: IProps) => {
  if (!textArticles?.[0]) return null;

  return (
    <section
      className={cn(styles.container, {
        [styles.removeTopBorder]: removeTopBorder
      })}
    >
      <SliceConfig config={sliceConfig}>
        {textArticles.map((textArticle) => {
          let i;

          return (
            <TextArticle key={textArticle?._key} data={textArticle} isStacked />
          );
        })}
      </SliceConfig>
    </section>
  );
};

export default StackedTextArticle;
