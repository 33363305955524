// extracted by mini-css-extract-plugin
export var container = "styles-module--container--McJL-";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--2d3ZI";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var list = "styles-module--list--N5glY";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";