import React from "react";
import { Button, SVG } from "~components";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    body: string;
    background: string;
    image: string;
  };
  children: React.ReactNode;
  className?: string;
}

const WebsiteHomepageFooter = () => {
  let i;

  return (
    <section className={styles.container}>
      <div className={styles.right}>
        <SVG svg="logomark" className={styles.logomark} />
        <p className="b1">Connect the Future</p>
      </div>

      <Button className={styles.button}>Enquire</Button>
    </section>
  );
};

export default WebsiteHomepageFooter;
