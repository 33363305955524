import React from "react";
import cn from "classnames";
import {
  Button,
  HTMLVideo,
  IntersectionAnimation,
  Grid,
  MeshBlanket
} from "~components";
import dayjs from "dayjs";

// import { Canvas } from "@react-three/fiber";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    title: string;
    lastUpdated: string;
    sliceConfig: ISliceConfig;
  };
}

const MeshHero = ({ data: { title, lastUpdated } }: IProps) => {
  const questions = `Questions?
  name@cmcconnect.com`;

  const formattedLastUpdated = dayjs(lastUpdated).format("hh:mmA DD/MM/YY");

  const lastUpdatedAt = `Last updated
  ${formattedLastUpdated}`;

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <Grid className={styles.leftGrid}>
            <div className={styles.marqueeContainer}>
              <div className={styles.verticalText}>
                <p>CONNECT</p>
                <p>CONNECT</p>
                <p>CONNECT</p>
              </div>
            </div>

            {/* <div className={styles.mesh}>
            <Canvas
              camera={{ zoom: 5, position: [0, 1, 0] }}
              style={{ background: `#05051B` }}
            >
              <ambientLight intensity={0.5} />

              <pointLight
                position={[0, 10, 30]}
                rotation={[0, 90, 0]}
                intensity={1}
                shadow-mapSize-height={1}
                shadow-mapSize-width={1}
                castShadow
              />

              <MeshBlanket />
            </Canvas>
          </div> */}
            {/* temporary video mesh */}
            <div className={styles.videoWrapper}>
              <HTMLVideo
                className={styles.video}
                src="https://player.vimeo.com/progressive_redirect/playback/867024555/rendition/1080p/file.mp4?loc=external&log_user=0&signature=5fddf2fd6699944202ce61873e813fd4b10b6a86b44f29170a036b6b746d769e"
                autoPlay
                muted
                loop
                playsInline
              />
            </div>
          </Grid>
        </div>

        <div className={styles.right}>
          {/* <Grid className={styles.grid}> */}
          <IntersectionAnimation className={styles.title}>
            <h1 className="d1">{title}</h1>
          </IntersectionAnimation>

          <IntersectionAnimation className={styles.table}>
            <p className={cn(["caption", styles.tableItem])}>{lastUpdatedAt}</p>
            <Button
              className={styles.tableItem}
              href="mailto:name@cmcconnect.com"
              variant="caption"
              outline
            >
              {questions}
            </Button>
            <Button
              className={styles.tableItem}
              href="https://www.cmcmarkets.com/en-gb/connect"
              variant="caption"
              outline
            >
              cmcconnect.com
            </Button>
          </IntersectionAnimation>
          {/* </Grid> */}
        </div>
      </div>
    </section>
  );
};

export default MeshHero;
